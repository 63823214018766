export const react_domain = `${window.location.origin}`;
export const base_url = `${window.location.origin}/backend/`
// export const base_url = `http://193.239.237.144:8002/`;
// export const base_url = `http://193.239.237.147:8000/`;
// export const base_url = `http://193.239.237.149:8000/`;
// export const base_url = `https://soptools.tradestreet.in/backend/`;
// export const base_url = `https://tools.tradestreet.in/backend/`;
// export const base_url = `https://tools.nextbrand.tech/backend/`;
// export const base_url = `https://tools.scalpingalgo.in//backend/`;
// export const base_url = `https://tools.easytrades.in//backend/`;



